import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";
import OptionPaymentRadioButton from "../../components/Common/payment_components/optionPaymentRadioButton";
import {
  paymentRequestUser,
  paymentRequestUserNoAuth,
} from "../../services/userServices/paymentUser.service";
import { ServicePackageInfo } from "../../components/Common/service_package_component/servicePackageTypes";
import { getAllPackageService } from "../../services/servicePackage.service";
import { packageAttributes } from "../servicePackage";
import { Skeleton } from "antd";
import ConfirmPaymentModal from "../../components/Common/payment_components/ConfirmPaymentModal";
import { getAccountExists } from "../../services/userServices/authUser.service";
import { checkRefCode } from "../../services/refcode.service";
import PackageRadioButton from "../../components/Common/payment_components/packageRadioButton";

interface DiscountModel {
  key: string;
  value: number;
}
interface PaymentConfirmData {
  customerName: string;
  customerPhoneNumber: string;
}

const isAuthenticated = (): boolean => {
  // Kiểm tra trạng thái đăng nhập của người dùng
  // Có thể là kiểm tra token trong localStorage, session, v.v.
  return !!sessionStorage.getItem("accessTokenUser");
};

function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function CheckOutOrder() {
  const [selectedPriority, setSelectedPriority] = useState<number>(1); // Initial state is the number 1
  const [servicePackageData, setServicePackageData] = useState<
    ServicePackageInfo[]
  >([]);
  const [selectedPackageID, setSelectedPackageID] = useState<string>("");
  const [index, setIndex] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [inputCode, setInputCode] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [paymentConfirmData, setPaymentConfirmData] =
    useState<PaymentConfirmData>({
      customerName: "",
      customerPhoneNumber: "",
    });
  const [isReadOnlyRefCode, setIsReadOnlyRefCode] = useState(false);
  const [refName, setRefName] = React.useState<string | null>(null);
  const [isValidRefcode, setIsValidRefcode] = useState(false);
  const [refCode, setRefCode] = useState<string | null>(null);
  const [discountPercentageValue, setDiscountPercentageValue] = useState(0);
  const [discountRefcodePercentageValue, setDiscountRefcodePercentageValue] =
    useState(0);
  const [isLifeTimeActive, setIsLifeTimeActive] = useState(false);
  const [correctRefCode, setCorrectRefCode] = useState("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [orderPrice, setOrderPrice] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const paymentAccess = sessionStorage.getItem("accessTokenUser");

  const allPackages = location.state?.allPackages || null;
  const indexPassed = location.state?.index + 1 || 1;

  useEffect(() => {
    setIndex(indexPassed);
    // handle data
    if (allPackages) {
      setServicePackageData(allPackages);
    } else {
      const fetchData = async () => {
        const packages = await getAllPackageService();

        const mergedData = packages.data.map((pkg: ServicePackageInfo) => ({
          ...pkg,
          packageColor:
            packageAttributes[pkg.priority]?.packageColor ?? "#FFFFFF",
          orignalPrice: packageAttributes[pkg.priority]?.orignalPrice ?? "",
          img: packageAttributes[pkg.priority]?.img ?? "",
        }));
        const sortedData = mergedData.sort(
          (a: { price: number }, b: { price: number }) => a.price - b.price
        );
        setServicePackageData(sortedData);
      };
      fetchData();
    }

    const queryParams = new URLSearchParams(location.search);
    const refcode = queryParams.get("refcode") || "";
    if (refcode) {
      setIsReadOnlyRefCode(true);
    } else {
      setIsReadOnlyRefCode(false);
    }
    handleGetRequestAffiliateData(refcode);
  }, [allPackages, location, navigate]);

  const handleGetRequestAffiliateData = async (ref: string) => {
    const trimmedRef = ref.trim();
    if (trimmedRef.length !== 6) return;
    try {
      const data = await checkRefCode(trimmedRef.toUpperCase());
      console.log(data, "checkrefcode");
      // if (data.status == 200) {
      const refName = `${data.data.first_name} ${data.data.last_name}`;
      setRefName(refName);
      setInputCode(data.data.personal_referal_code);
      setRefCode(data.data.personal_referal_code);
      setCorrectRefCode(data.data.personal_referal_code);
      setIsValidRefcode(true);
      // setIsValidRefcode(false);
      // }
    } catch (error) {
      setRefName("Mã người giới thiệu không tồn tại");
      setIsValidRefcode(false);
      setInputCode(trimmedRef);
    }
  };

  // Automatically select the first package when data is available
  useEffect(() => {
    if (servicePackageData.length > 0 && servicePackageData[index - 1]) {
      const firstPackage = servicePackageData[index - 1];
      handlePriorityChange(
        index,
        firstPackage.title,
        firstPackage.price,
        firstPackage.title,
        firstPackage.discountPrice,
        firstPackage.ID
      );
    }
  }, [servicePackageData, index]);

  useEffect(() => {
    // Khi refName thay đổi, cập nhật lại selectedPrice nếu đã chọn gói
    if (selectedPriority) {
      const selectedPackage = servicePackageData.find(
        (pkg) => pkg.priority === selectedPriority
      );
      if (selectedPackage) {
        setOrderPrice(selectedPackage.originPrice);
        setSelectedPrice(
          refName ? selectedPackage.discountPrice : selectedPackage.price
        );
      }
    }
  }, [refName, selectedPriority, servicePackageData]);

  useEffect(() => {
    setTotalPrice(selectedPrice);
  }, [selectedPrice]);

  useEffect(() => {
    handleDiscountAffiliate(selectedPackageID, refName);
  }, [refName]);

  const handleInputEmailChange = (e: any) => {
    setInputEmail(e.target.value);
  };

  const validateEmailOrPhone = (value: string) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const phoneRegex = /^\d{10}$/;
    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const paymentGate = () => {
    isAuthenticated() ? handlePayment() : openConfirmModal();
  };
  const openConfirmModal = async () => {
    // First, validate the input
    if (!inputEmail || !validateEmailOrPhone(inputEmail)) {
      alert("Bạn chưa nhập Email/SĐT hợp lệ");
      return;
    }

    // Open the modal and show a loading message
    setIsModalVisible(true);
    setPaymentConfirmData({
      customerName: `Đang kiểm tra dữ liệu của quý khách ${inputEmail} xin hãy chờ giây lát...`,
      customerPhoneNumber: "",
    });

    // Fetch account data
    try {
      const accountStatus = await getAccountExists(inputEmail);
      console.log(accountStatus, "AccountExisted");
      // Set the data based on the response
      if (accountStatus && accountStatus.status === "OK") {
        const customerName = accountStatus.data.name
          ? accountStatus.data.name
          : `${inputEmail} (Tài khoản đang dùng tên mặc định)`;
        if (accountStatus.data.isLifeTimeActive == true) {
          // console.log(accountStatus.data.isLifeTimeActive, "AccountExisted");
          setIsLifeTimeActive(true);
        } else {
          // console.log(accountStatus.data.isLifeTimeActive, "AccountExisted");
          setIsLifeTimeActive(false);
        }

        setPaymentConfirmData({
          customerName: customerName,
          customerPhoneNumber: inputEmail,
        });
      } else {
        setPaymentConfirmData({
          customerName: `Hệ thống sẽ tự động tạo tài khoản với email/số điện thoại ${inputEmail}. \n Quý khách hãy liên hệ affiliate hoặc hotline của chúng tôi để nhận được mã truy cập.`,
          customerPhoneNumber: inputEmail,
        });
      }
    } catch (error) {
      // Handle any errors in fetching data
      setPaymentConfirmData({
        customerName:
          "Không thể kiểm tra dữ liệu của quý khách. Vui lòng thử lại sau.",
        customerPhoneNumber: inputEmail,
      });
    }
  };

  const closeConfirmModal = () => {
    setIsModalVisible(false);
  };

  const handlePayment = async () => {
    const refCode = correctRefCode;
    // const refCode = "G4S64S";
    // console.log("2220", refCode);
    console.log(window.location.href.split("?")[0]);
    //  ********
    if (paymentAccess == null) {
      var url = window.location.href;
      if (url.indexOf("?") > -1) {
        url = window.location.href.split("?")[0];
      }
      const returnUrl = url + "/paymentprocess";

      const selectedPackage = servicePackageData[selectedPriority as number];
      console.log(selectedPackageID);
      if (isValidEmail(inputEmail)) {
        const data = await paymentRequestUserNoAuth(
          selectedPackageID,
          returnUrl,
          inputEmail,
          undefined,
          refCode ?? undefined
        );
        if (data.msg == "Success") {
          sessionStorage.setItem("paymentProcess", "true");
          window.open(data.data);
          // Close the current window
          navigate("/paymentprocess", { state: data.data });
        }
      } else {
        const data = await paymentRequestUserNoAuth(
          selectedPackageID,
          returnUrl,
          undefined,
          inputEmail,
          refCode ?? undefined
        );
        if (data.msg == "Success") {
          sessionStorage.setItem("paymentProcess", "true");
          window.location.href = data.data;
          // Close the current window
          navigate("/paymentprocess", { state: data.data });
        }
      }
    } else {
      var url = window.location.href;
      if (url.indexOf("?") > -1) {
        url = window.location.href.split("?")[0];
      }
      const returnUrl = url + "/paymentprocess";
      // const selectedPackage = servicePackageData[selectedPriority as number];
      const data = await paymentRequestUser(
        selectedPackageID,
        returnUrl,
        refCode ?? undefined
      );
      if (data.msg == "Success") {
        sessionStorage.setItem("paymentProcess", "true");
        window.location.href = data.data; // Close the current window
        navigate("/paymentprocess", { state: data.data });
      }
    }
  };

  const handleDiscountAffiliate = (
    packageID: string,
    refname: string | null
  ) => {
    if (refname === "Mã người giới thiệu không tồn tại" || refname === null) {
      // Nếu mã người giới thiệu không tồn tại hoặc null, không tính giảm giá
      setDiscountRefcodePercentageValue(0);
      console.warn("Invalid refname:", refname);
      switch (packageID) {
        case "clt83tfo6000108la76z54ev0":
          setDiscountPercentageValue(31);
          break;
        case "clt83waa0000208laeplah3jv":
          setDiscountPercentageValue(29);
          break;
        case "clt83zmsi000508la3s779oa0":
          setDiscountPercentageValue(25);
          break;
        case "clt83z8jh000408lahzfcedc8":
          setDiscountPercentageValue(33);
          break;
        default:
          setDiscountPercentageValue(0);
          console.warn("Unknown package id:", packageID);
      }
    }
    // refcode apply
    else {
      switch (packageID) {
        case "clt83tfo6000108la76z54ev0":
          setDiscountRefcodePercentageValue(45);
          break;
        case "clt83waa0000208laeplah3jv":
          setDiscountRefcodePercentageValue(43);
          break;
        case "clt83zmsi000508la3s779oa0":
          setDiscountRefcodePercentageValue(40);
          break;
        case "clt83z8jh000408lahzfcedc8":
          setDiscountRefcodePercentageValue(40);
          break;
        default:
          setDiscountRefcodePercentageValue(0);
          console.warn("Unknown package id:", packageID);
      }
    }
  };

  const handlePriorityChange = (
    priority: number,
    label: string,
    price: number,
    title: string,
    discountPrice: number,
    ID: string
  ) => {
    setSelectedPriority(priority);
    setSelectedLabel(label);
    setSelectedPrice(
      refName == null ||
        refName == "Mã người giới thiệu không tồn tại" ||
        refName.length < 6
        ? price
        : discountPrice
    );
    setSelectedPackageID(ID);
    handleDiscountAffiliate(ID, refName);
  };

  const applyRefCode = async () => {
    if (inputCode.trim().length !== 6)
      return alert("Mã Giới Thiệu Phải Có 6 Ký Tự");
    handleGetRequestAffiliateData(inputCode);
  };

  return (
    <div className="bg-[#F8F8F8] w-full">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pt-48 pv:max-ph:pt-32 ph:max-md:pt-40 ">
        <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] mb-16">
          <div className="flex pv:max-xl:flex-col pv:max-xl:items-center pv:max-xl:gap-5 h-full w-full justify-between">
            {/* left section */}
            <div className="w-[75%]  pv:max-xl:w-[100%] bg-white rounded-[30px] pv:max-lg:p-7 p-10">
              <div className="gap-6 pv:max-md:gap-3 flex flex-col">
                <p className="font-bold text-[44px] pv:max-md:text-[20px] md:max-lg:text-[25px] text-[#142548]">
                  Thông tin liên hệ
                </p>
                {paymentAccess != null ? (
                  <p className="font-semibold text-[23px] pv:max-md:text-[16px] text-[#142548]">
                    Hệ thống sẽ ghi nhận tự động dựa vào đăng nhập của quý khách
                  </p>
                ) : (
                  <div>
                    {/* <div>
                      <p className="font-semibold text-[23px] text-[#142548]">
                        Họ và tên
                      </p>
                      <input
                        className="w-full h-12 border-2 border-[#142548] rounded-full mt-1 sm:mt-3 px-4  text-[23px] text-[#142548] font-semibold outline-none"
                        placeholder="Họ và tên"
                      ></input>
                    </div> */}
                    <div>
                      <p className="font-semibold mb-5 pv:max-md:mb-[10px] text-[23px] pv:max-md:text-[18px] text-[#142548]">
                        Số điện thoại/ Email:
                      </p>
                      <input
                        className="w-full h-12 border-2 border-[#142548] pv:max-sm:rounded-[25px] rounded-full mt-1 sm:mt-3 px-4 text-[23px] pv:max-md:text-[18px] text-[#142548] font-semibold outline-none"
                        placeholder="Số điện thoại/ Email"
                        value={inputEmail}
                        onChange={handleInputEmailChange}
                      />
                    </div>
                  </div>
                )}

                <p className="font-semibold mt-5 pv:max-md:mt-[10px] text-[23px] pv:max-md:text-[18px] text-[#142548]">
                  Gói đã chọn
                </p>
                <div>
                  {servicePackageData.length > 0 ? (
                    <PackageRadioButton
                      refname={refName}
                      selectedValue={selectedPriority}
                      onChange={handlePriorityChange}
                      servicePackageData={servicePackageData}
                    />
                  ) : (
                    <div className=" w-full flex justify-start items-center">
                      <Skeleton active className="w-[100%]" />
                    </div>
                  )}
                </div>
                <p className="font-bold text-[44px] pv:max-md:text-[20px] text-[#142548]">
                  Mã Người giới thiệu
                </p>
                <div className="flex justify-between border-2 border-[#142548] rounded-full px-10 py-2 pv:max-ph:py-[5px] ph:max-md:px-[10px] pv:max-ph:px-[5px]">
                  <input
                    className="w-[80%] md:max-lg:w-[40%] text-[20px] pv:max-md:text-[10px] pv:max-md:w-[50%] font-semibold uppercase pl-3 text-[#142548] select-none focus:outline-none"
                    placeholder="Mã giới thiệu"
                    value={inputCode}
                    onContextMenu={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onChange={(e) => {
                      const value = e.target.value;
                      setInputCode(value);
                      if (value.trim().length === 6) {
                        handleGetRequestAffiliateData(value);
                      }
                    }}
                    readOnly={isReadOnlyRefCode}
                    maxLength={6}
                  />

                  <button
                    className="bg-[#142548] w-auto font-bold py-1 px-5 rounded-[33px] text-white"
                    onClick={applyRefCode}
                  >
                    <p className="text-[20px] pv:max-ph:text-[8px] ph:max-md:text-[12px]">
                      Áp dụng
                    </p>
                  </button>
                </div>

                {inputCode.length == 6 ? (
                  <p className="font-medium text-[20px] pv:max-md:text-[12px] text-[#142548] capitalize">
                    {refName}
                  </p>
                ) : (
                  <p className="font-medium text-[20px] pv:max-md:text-[12px] text-[#142548] capitalize"></p>
                )}
              </div>
            </div>
            {/* right section */}
            <div className="w-[23%] pv:max-xl:w-[100%] bg-white rounded-[30px] pv:max-lg:p-7 p-10">
              <div className="gap-6 pv:max-md:gap-3 flex flex-col">
                <p className="font-bold text-[44px] pv:max-md:text-[20px] md:max-lg:text-[25px] text-[#142548]">
                  Đơn hàng
                </p>
                <div>
                  <div className="w-full flex justify-between">
                    <p className="font-semibold text-[23px] pv:max-md:text-[15px] text-[#142548]">
                      {selectedLabel}
                    </p>
                    <div>
                      <p className="font-medium text-[23px] pv:max-md:text-[15px] text-[#142548]">
                        {orderPrice.toLocaleString("de-DE")}
                      </p>
                    </div>
                  </div>
                </div>
                {refCode === "" ||
                refCode === null ||
                refName == "Mã người giới thiệu không tồn tại" ? (
                  <div>
                    <div className="flex justify-end">
                      <p className="font-medium text-left text-[23px] pv:max-md:text-[15px] text-[#F56177]">
                        - {discountPercentageValue}%
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="font-semibold text-[23px] pv:max-md:text-[20px] text-[#142548]">
                      Mã giới thiệu
                    </p>
                    <div className="w-full flex justify-between">
                      <div>
                        <p className="font-normal text-[23px] pv:max-md:text-[15px] text-[#142548]">
                          {refCode}
                        </p>
                        <p className="font-medium text-[23px] pv:max-md:text-[15px] text-[#F56177]">
                          {refName}
                        </p>
                      </div>
                      <div>
                        <div className="flex justify-end">
                          <p className="font-medium text-left text-[23px] pv:max-md:text-[15px] text-[#F56177]">
                            - {discountRefcodePercentageValue}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Total amount after discounts */}
                <div className="w-full flex justify-between align-bottom items-end">
                  <p className="font-semibold text-[34px] pv:max-md:text-[20px] text-[#142548]">
                    Tổng cộng
                  </p>
                  <p className="font-medium text-[25px] pv:max-md:text-[17px] text-[#142548]">
                    {totalPrice.toLocaleString("de-DE")}
                  </p>
                </div>
                <div className="flex justify-end">
                  <button
                    className="bg-[#142548] font-bold py-1 px-5 rounded-[33px] text-white"
                    onClick={paymentGate}
                  >
                    <p className="text-[20px] pv:max-md:text-[14px]">
                      Thanh toán
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {isModalVisible && (
        <div className="">
          <ConfirmPaymentModal
            handleCancel={closeConfirmModal}
            handlePayment={handlePayment}
            customerName={paymentConfirmData.customerName}
            customerPhoneNumber={paymentConfirmData.customerPhoneNumber}
            isDisplayPaymentButton={isLifeTimeActive}
          />
        </div>
      )}
    </div>
  );
}

export default CheckOutOrder;
