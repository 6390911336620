import React, { useState } from "react";
import ModalConfirm from "./modalConfirm";
import { createNewQASupport } from "../../../services/QA/qa.service";

interface QASupport {
  fullName: string;
  emailOrPhone: string;
  question: string;
}

const FooterBlog = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [formSupport, setFormSupport] = useState<QASupport>({
    fullName: "",
    emailOrPhone: "",
    question: "",
  }); // State for form data storage

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormSupport((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateEmailOrPhone = (value: string) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const phoneRegex = /^\d{10}$/;
    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Validate that all fields are filled
    if (!formSupport.fullName.trim()) {
      alert("Họ và tên không được để trống.");
      return;
    }
    if (!formSupport.emailOrPhone.trim()) {
      alert("Số điện thoại hoặc Email không được để trống.");
      return;
    }
    if (!validateEmailOrPhone(formSupport.emailOrPhone)) {
      alert("Số điện thoại hoặc Email không hợp lệ.");
      return;
    }
    if (!formSupport.question.trim()) {
      alert("Nội dung câu hỏi không được để trống.");
      return;
    }
    console.log("Form Submitted with values:", formSupport);
    try {
      const data = await createNewQASupport(
        formSupport.fullName,
        formSupport.emailOrPhone,
        formSupport.question
      );
      if (data.status == 201 || data.status == 200) {
        console.log("Form Success:", data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Form Fail:", error);
      alert(error);
      alert("Lỗi");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="bg-[#66b0ff] flex justify-end rounded-[30px] w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] pv:max-md:flex-col">
      {/* Left Section - Text and Form */}
      <div className="w-[55%] space-y-6 p-10 pv:max-md:w-full pv:max-md:p-8">
        <p className="font-[800] text-[50px] leading-[50px] text-justify text-white pv:max-md:text-center pv:max-md:text-[25px] pv:max-md:leading-[25px]">
          Vui lòng điền thông tin để Why Kids hỗ trợ giải đáp thắc mắc của phụ
          huynh
        </p>
        <form
          className="space-y-4 w-[90%] pv:max-md:w-full"
          onSubmit={handleSubmit}
        >
          <div className="flex space-x-4 pv:max-md:space-x-0 pv:max-md:flex-col">
            <div className=" w-1/2 pv:max-md:w-full">
              <p className="pl-5 pv:max-md:pl-0 text-white font-semibold text-[20px] pv:max-md:text-[15px]">
                Họ và tên:
              </p>
              <input
                type="text"
                name="fullName"
                placeholder="Nhập tên"
                value={formSupport.fullName}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-[32px] bg-[#9dcbfc] text-gray-800 placeholder-gray-500"
              />
            </div>
            <div className=" w-1/2 pv:max-md:w-full">
              <p className="pl-5 pv:max-md:pl-0 text-white font-semibold text-[20px] pv:max-md:text-[15px]">
                Số điện thoại/ Email:
              </p>
              <input
                type="text"
                name="emailOrPhone"
                value={formSupport.emailOrPhone}
                placeholder="Nhập số điện thoại hoặc Email"
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-[32px] bg-[#9dcbfc] text-gray-800 placeholder-gray-500"
              />
            </div>
          </div>
          <div>
            <div className="pl-5 pv:max-md:pl-0">
              <p className="text-white font-semibold text-[20px]">
                Nội dung cần tư vấn:
              </p>
            </div>
            <textarea
              name="question"
              placeholder="Nhập nội dung cần tư vấn"
              value={formSupport.question}
              onChange={handleInputChange}
              className="w-full px-4 py-2 rounded-[32px] pv:max-md:rounded-[20px] bg-[#9dcbfc] text-gray-800 placeholder-gray-500 h-28"
            />
          </div>
          <button
            type="submit"
            className="bg-[#142548] font-bold text-white px-10 py-2 rounded-[23px]"
          >
            Gửi thông tin
          </button>
        </form>
      </div>

      {/* Right Section - Image */}
      <div className="w-[38%] flex items-end justify-end pv:max-md:justify-center pv:max-md:items-center pv:max-md:w-full pv:max-md:px-10">
        <img
          src="/library_img/footer/banner_footer_blog.png"
          alt="Why Kids Banner"
          className="w-full object-contain"
        />
      </div>

      {/* Modal */}
      {isModalOpen && <ModalConfirm handleCancel={closeModal} />}
    </div>
  );
};

export default FooterBlog;
