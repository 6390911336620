// InfoCard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

const InfoCard = ({ info }: any) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`../library/detail/${info.key}`, {
      state: { data: info },
    });
    // Log Firebase analytics event
    logEvent(analytics, "page_view", {
      page_title: "web đảo " + info.title,
    });
  };

  return (
    <div
      onClick={handleClick}
      className="w-[394px] cursor-pointer	pv:max-md:w-[261px] pv:max-md:h-[346px]  md:max-lg:w-[330px] lg:max-xl:w-[400px] sm:max-md:w-[280px] transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300 h-[522px] pv:max-xl:h-auto py-10 px-4 flex-col bg-white rounded-[20px]"
      style={{
        boxShadow: "0px 4.58px 11.45px rgba(0, 0, 0, 0.15)",
      }}
    >
      <img
        className="px-5 w-full object-contain h-[80%] sm:max-md:h-[60%]"
        src={info.image}
        alt={info.title}
      />
      <div className="flex justify-center items-center">
        <p className="text-[40px] pv:max-md:text-[30px] text-[#65AFFF] text-center uppercase font-bold">
          {info.title.split(" ").slice(0, 2).join(" ")}
          <br />
          {info.title.split(" ").slice(2).join(" ")}
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
