import React, { useEffect, useState } from "react";
import Footer from "../../components/Common/footer";
import backgroundImg from "../../assets/login/bg_login.png";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Common/header/header";
import ServiceCard from "../../components/Common/service_package_component/service_card";
import { getAllPackageService } from "../../services/servicePackage.service";
import { ServicePackageInfo } from "../../components/Common/service_package_component/servicePackageTypes";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

interface PackageAttributes {
  img?: string;
  packageColor?: string;
  orignalPrice?: number;
}

export const packageAttributes: { [priorityKey: number]: PackageAttributes } = {
  1: { img: "baby1", packageColor: "#65AFFF" },
  2: { img: "baby2", packageColor: "#9FD356" },
  3: { img: "baby3", packageColor: "#F7BF57" },
  4: { img: "baby4", packageColor: "#F56177" },
};

function ServicePackage() {
  const [expandedCard, setExpandedCard] = useState<string | null>(null);
  const [servicePackageData, setServicePackageData] = useState<
    ServicePackageInfo[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [clickCount, setClickCount] = useState(0);
  const [active, setActive] = useState<number>(0);
  const init = async () => {
    try {
      const dataPackage = await getAllPackageService();
      const mergedData = dataPackage.data.map((pkg: ServicePackageInfo) => ({
        ...pkg,
        packageColor:
          packageAttributes[pkg.priority]?.packageColor ?? "#FFFFFF",
        orignalPrice: packageAttributes[pkg.priority]?.orignalPrice ?? 0,
        img: packageAttributes[pkg.priority]?.img ?? "",
      }));
      // sort ASC by price
      const sortedData = mergedData.sort(
        (a: { price: number }, b: { price: number }) => a.price - b.price
      );

      setServicePackageData(sortedData);
      setLoading(false); // Set loading to false after data is fetched
      // console.log(sortedData);
    } catch (error) {
      console.error("Error fetching package data:", error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handle2ndCardClick = (card: ServicePackageInfo, index: number) => {
    navigate("/checkout-order", {
      state: {
        selectedCard: card,
        allPackages: servicePackageData,
        index: index,
      },
    });
    setClickCount(0); // Reset click count after double-click action
  };

  const handle1stCardClick = (cardType: string) => {
    if (expandedCard === cardType) {
      setClickCount((prevCount) => prevCount + 1);
    } else {
      setExpandedCard(cardType);
      setClickCount(1);
    }
  };

  return (
    <div
      className="w-full relative"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="pt-48 pb-10 lg:max-dvmax:px-20 dhmin:max-dh:py-42 px-32 pv:max-lg:px-0 pv:max-md:pt-40">
        <div className="flex justify-center items-center h-auto pv:max-sm:h-fit pv:max-sm:pb-10 gap-8 dv:max-dvmax:gap-16 dvmax:max-dhmin:gap-[5rem] xlmax:max-dv:gap-20 dv:max-dh:gap-28 lg:max-xl:gap-[60px] md:max-xl:h-fit md:max-xl:pb-6 flex-wrap">
          {loading ? (
            <div className="w-full h-[400px] flex justify-center items-center">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
              />
            </div>
          ) : (
            <>
              <div className=" w-full flex pv:max-md:flex-col pv:max-md:gap-10 pv:max-md:items-center md:max-lg:flex-wrap md:max-lg:justify-around md:max-lg:gap-10  md:max-lg:px-14 justify-between">
                {servicePackageData.map((card, index) => (
                  <ServiceCard
                    key={card.ID}
                    packageColor={card.packageColor || "#FFFFFF"}
                    imageUrl={card.img || ""}
                    originPrice={card.originPrice || 0}
                    packageType={card.title || "0"}
                    price={card.price}
                    pricePerMonth={card.pricePerMonth}
                    isExpanded={expandedCard === card.title}
                    on1stClick={() => handle1stCardClick(card.title!)}
                    on2ndClick={() => handle2ndCardClick(card, index)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ServicePackage;
