import { API_URL } from "../../config";
import axios from "axios";

export const createNewQASupport = async (
  fullname: string,
  emailOrPhone: string,
  question: string
) => {
  const response = await axios.post(`${API_URL}/qa`, {
    fullName: fullname,
    emailOrPhone: emailOrPhone,
    question: question,
  });
  return response;
};
